.App {
  text-align: center;
  min-height: 100vh;
  color: white;
  position: relative;
}

.App-header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.wallet-checker {
  margin-top: 2rem;
  padding: 2rem;
  background-color: rgba(26, 29, 36, 0.9);
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .input-group {
    flex-direction: column;
  }

  .wallet-checker {
    padding: 1rem;
    width: 85%;
  }

  .status-results {
    flex-direction: column;
  }

  .status-box {
    min-width: auto;
  }

  .social-buttons {
    flex-direction: column;
  }
}

.wallet-input {
  flex: 1;
  padding: 0.8rem;
  border-radius: 5px;
  border: 1px solid rgba(97, 218, 251, 0.3);
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.9);
}

.check-button {
  padding: 0.8rem 1.5rem;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  color: #282c34;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.check-button:hover {
  background-color: #4fa8c7;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(97, 218, 251, 0.4);
}

.check-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.error {
  color: #ff6b6b;
  margin-top: 1rem;
  background: rgba(255, 107, 107, 0.1);
  padding: 0.5rem;
  border-radius: 5px;
}

.status-results {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.status-box {
  flex: 1;
  padding: 1.5rem;
  background-color: rgba(42, 47, 58, 0.8);
  border-radius: 8px;
  min-width: 250px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(97, 218, 251, 0.2);
}

.status-box h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #61dafb;
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.3);
}

.result {
  margin: 0;
  padding: 0.8rem;
  border-radius: 4px;
}

.result.success {
  background-color: rgba(97, 218, 251, 0.15);
  color: #61dafb;
  border: 1px solid rgba(97, 218, 251, 0.3);
}

.result.failure {
  background-color: rgba(255, 107, 107, 0.15);
  color: #ff6b6b;
  border: 1px solid rgba(255, 107, 107, 0.3);
}

.congrats-message {
  margin-top: 2rem;
  padding: 2rem;
  background-color: rgba(97, 218, 251, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(97, 218, 251, 0.3);
  backdrop-filter: blur(10px);
}

.congrats-message h2 {
  color: #61dafb;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.3);
}

.congrats-message p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
}

.social-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.social-button {
  padding: 1rem 2rem;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.social-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.social-button.discord {
  background-color: #5865F2;
  color: white;
}

.social-button.x {
  background-color: #000000;
  color: white;
}

.social-links {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.social-link {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  text-decoration: none;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.social-link:hover {
  transform: scale(1.1) translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.social-link.x {
  background-color: #000000;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-link.discord {
  background-color: #5865F2;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-link svg {
  width: 20px;
  height: 20px;
} 